import dayjs, { Dayjs, OpUnitType } from 'dayjs';
import timezone from 'dayjs/plugin/timezone.js';
import utc from 'dayjs/plugin/utc.js';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore.js';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter.js';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export const dateCetFormatted = (date: string) => {
    return formatDate(dateCET(date));
};

export const formatDate = (date: dayjs.Dayjs) => {
    return date.format('DD.MM.YYYY');
};

export const todayCetFormatted = () => {
    return formatDate(todayCET());
};

export const todayCET = () => {
    return dayjs(dayjs().format('YYYY-MM-DD')).tz('Europe/Vienna');
};

export const dateCET = (date: string) => {
    return dayjs(date).tz('Europe/Vienna');
};

/**
 *
 * @param startDate
 * @param today
 * @param endDate
 * @param granularity i.e. 'month' compares month and year; 'day' compares day, month and year
 * * @returns
 */
export function todayIsWithinDateRange(
    startDate: Dayjs,
    today: Dayjs,
    endDate: Dayjs,
    granularity: OpUnitType
): boolean {
    return (
        startDate.isSameOrBefore(today, granularity) &&
        today.isSameOrBefore(endDate, granularity)
    );
}
